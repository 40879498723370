import PerfectScrollbar from "perfect-scrollbar";
$(function(){
  'use strict'

  if($('.contact-content-body').length < 1) {
    return;
  }

  // $('[data-toggle="tooltip"]').tooltip();

  // set active contact from list to show in desktop view by default
  if(window.matchMedia('(min-width: 992px)').matches) {
    $('.search-wrapper-js.contact-list .media:first-of-type').addClass('active');
  }

  const contactSidebar = new PerfectScrollbar('.contact-sidebar-body', {
    suppressScrollX: true
  });

  new PerfectScrollbar('.contact-content-body', {
    suppressScrollX: true
  });

  new PerfectScrollbar('.contact-content-sidebar', {
    suppressScrollX: true
  });

  $('.contact-navleft .nav-link').on('shown.bs.tab', function(e) {
    contactSidebar.update()
  })

  // UI INTERACTION
  $('.contact-list .media').on('click', function(e) {
    e.preventDefault();

    $('.contact-list .media').removeClass('active');
    $(this).addClass('active');

    var cId = $(this).find('.c-id-js').text();
    var cFirstName = $(this).find('.c-first-name-js').text();
    var cLastName = $(this).find('.c-last-name-js').text();
    var cTitle = $(this).find('.c-title-js').text();

    var cOrganization = $(this).find('.c-organization-js').text();
    var cDepartment = $(this).find('.c-department-js').text();
    var cCreatedAt = $(this).find('.c-created-at-js').text();

    var cOfficeLine = $(this).find('.c-office-line-js').text();
    var cMobile = $(this).find('.c-mobile-js').text();
    var cEmail = $(this).find('.c-email-js').text();

    var cAddressLine1 = $(this).find('.c-address-line-1-js').text();
    var cAddressLine2 = $(this).find('.c-address-line-2-js').text();
    var cZip = $(this).find('.c-zip-js').text();

    var cCity = $(this).find('.c-city-js').text();
    var cState = $(this).find('.c-state-js').text();
    var cCountry = $(this).find('.c-country-js').text();
    var cTags = $(this).find('.c-tags-js').html();

    $('#first-name-js').text(cFirstName || "-");
    $('#last-name-js').text(cLastName || "-");
    $('#title-js').text(cTitle || "-");

    $('#organization-js').text(cOrganization || "-");
    $('#department-js').text(cDepartment || "-");
    $('#created-at-js').text(cCreatedAt);

    $('#office-line-js').text(cOfficeLine || "-");
    $('#mobile-js').text(cMobile || "-");
    $('#email-js').text(cEmail || "-");

    $('#address-line-1-js').text(cAddressLine1 || "-");
    $('#address-line-2-js').text(cAddressLine2 || "-");
    $('#zip-js').text(cZip || "-");

    $('#city-js').text(cCity || "-");
    $('#state-js').text(cState || "-");
    $('#country-js').text(cCountry || "-");
    $('#tags-js').html(cTags);

    $('#edit-contact-link-js').data("current-id", cId);

    $('#toggle-active-contact-js').data("current-id", cId);

    var selected = $(`#toggle-active-contact-js-${cId}`).html();
    var current = $("#toggle-active-contact-js").html(selected);

    var cAvatar = $(this).find('.avatar').clone();

    cAvatar.removeClass (function (index, className) {
      return (className.match (/(^|\s)avatar-\S+/g) || []).join(' ');
    });
    cAvatar.addClass('avatar-xl');

    $('#contactAvatar .avatar').replaceWith(cAvatar);


    // showing contact information when clicking one of the list
    // for mobile interaction only
    if(window.matchMedia('(max-width: 991px)').matches) {
      $('body').addClass('contact-content-show');
      $('body').removeClass('contact-content-visible');

      $('#mainMenuOpen').addClass('d-none');
      $('#contactContentHide').removeClass('d-none');
    }
  })


  // going back to contact list
  // for mobile interaction only
  $('#contactContentHide').on('click touch', function(e){
    e.preventDefault();

    $('body').removeClass('contact-content-show contact-options-show');
    $('body').addClass('contact-content-visible');

    $('#mainMenuOpen').removeClass('d-none');
    $(this).addClass('d-none');
  });

  $('#contactOptions').on('click', function(e){
    e.preventDefault();
    $('body').toggleClass('contact-options-show');
  })

  $(window).resize(function(){
    $('body').removeClass('contact-options-show');
  })

})
