// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("@nathanvda/cocoon")
require("trix")
require("autonumeric")
require("@rails/actiontext")
require("@yaireo/tagify")


import "../stylesheets/calendar_css/fullcalender.min.css";
import "../stylesheets/calendar_css/dashforge.css";
import "../stylesheets/calendar_css/dashforge_calendar.css";
import "../stylesheets/calendar_css/custom.css";

import "../stylesheets/actiontext.scss";
import "@yaireo/tagify/src/tagify.scss";
import "../stylesheets/dashforge.scss";
// import "../stylesheets/dashforge.calendar.scss";
import "../stylesheets/dashforge.contacts.scss";
import "../stylesheets/demo.scss";


import "bootstrap";
import "jquery-steps/build/jquery.steps";
import AutoNumeric from 'autonumeric/src/main';
import Tagify from '@yaireo/tagify';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import listPlugin from '@fullcalendar/list';
// import dashforgeCalendar from './dashforge.calendar';
import dashforgeContacts from './dashforge.contacts';
import './dashboard-two';

import "../stylesheets/calendar_css/main.css";


const feather = require('feather-icons');

window.jQuery = $;
window.$ = $;

document.addEventListener("turbolinks:load", () => {

// let calendarEl = document.getElementById('calendar');
// const tz = $("#tz").data("server-tz-js");
// console.log("Current Timezone: ", tz)
//   window.calendar = new Calendar(calendarEl, {
//   timeZone: tz,
//   schedulerLicenseKey: '0807627048-fcs-1624819105',
//   contentHeight: "auto",
//   plugins: [ interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin, bootstrapPlugin ],
//   headerToolbar: {
//     left: 'prev,next today',
//     center: 'title',
//     right: 'timeGridDay,timeGridWeek,listWeek'
//   },
//   navLinks: true,
//   editable: true,
//   dayMaxEvents: true,
//   // events: window.events
//   eventClick: function(info) {
//     info.jsEvent.preventDefault();
//     jQuery('#modalCalendarEvent').modal('show');
//   }
//   });

//   if($('#calendar').length > 0) {
//     calendar.render();
//   }

  feather.replace();

  ////////// NAVBAR //////////

  // // Initialize PerfectScrollbar of navbar menu for mobile only
  // if(window.matchMedia('(max-width: 991px)').matches) {
  //   const psNavbar = new PerfectScrollbar('#navbarMenu', {
  //     suppressScrollX: true
  //   });
  // }

  // Showing sub-menu of active menu on navbar when mobile
  function showNavbarActiveSub() {
    if(window.matchMedia('(max-width: 991px)').matches) {
      $('#navbarMenu .active').addClass('show');
    } else {
      $('#navbarMenu .active').removeClass('show');
    }
  }

  showNavbarActiveSub()
  $(window).resize(function(){
    showNavbarActiveSub()
  })

  // Initialize backdrop for overlay purpose
  $('body').append('<div class="backdrop"></div>');


  // Showing sub menu of navbar menu while hiding other siblings
  $('.navbar-menu .with-sub .nav-link').on('click', function(e){
    e.preventDefault();
    $(this).parent().toggleClass('show');
    $(this).parent().siblings().removeClass('show');

    if(window.matchMedia('(max-width: 991px)').matches) {
      psNavbar.update();
    }
  })

  // Closing dropdown menu of navbar menu
  $(document).on('click touchstart', function(e){
    e.stopPropagation();

    // closing nav sub menu of header when clicking outside of it
    if(window.matchMedia('(min-width: 992px)').matches) {
      var navTarg = $(e.target).closest('.navbar-menu .nav-item').length;
      if(!navTarg) {
        $('.navbar-header .show').removeClass('show');
      }
    }
  })

  $('#mainMenuClose').on('click', function(e){
    e.preventDefault();
    $('body').removeClass('navbar-nav-show');
  });

  $('#sidebarMenuOpen').on('click', function(e){
    e.preventDefault();
    $('body').addClass('sidebar-show');
  })

  // Navbar Search
  $('#navbarSearch').on('click', function(e){
    e.preventDefault();
    $('.navbar-search').addClass('visible');
    $('.backdrop').addClass('show');
  })

  $('#navbarSearchClose').on('click', function(e){
    e.preventDefault();
    $('.navbar-search').removeClass('visible');
    $('.backdrop').removeClass('show');
  })



  ////////// SIDEBAR //////////

  // Initialize PerfectScrollbar for sidebar menu
  if($('#sidebarMenu').length) {
    const psSidebar = new PerfectScrollbar('#sidebarMenu', {
      suppressScrollX: true
    });


    // Showing sub menu in sidebar
    $('.sidebar-nav .with-sub').on('click', function(e){
      e.preventDefault();
      $(this).parent().toggleClass('show');

      psSidebar.update();
    })
  }

  $('#mainMenuOpen').on('click touchstart', function(e){
    e.preventDefault();
    $('body').addClass('navbar-nav-show');
  })

  $('#sidebarMenuClose').on('click', function(e){
    e.preventDefault();
    $('body').removeClass('sidebar-show');
  })

  // hide sidebar when clicking outside of it
  $(document).on('click touchstart', function(e){
    e.stopPropagation();

    // closing of sidebar menu when clicking outside of it
    if(!$(e.target).closest('.burger-menu').length) {
      var sb = $(e.target).closest('.sidebar').length;
      var nb = $(e.target).closest('.navbar-menu-wrapper').length;
      if(!sb && !nb) {
        if($('body').hasClass('navbar-nav-show')) {
          $('body').removeClass('navbar-nav-show');
        } else {
          $('body').removeClass('sidebar-show');
        }
      }
    }
  });

  var inputElm = document.getElementById("registration_tag_list"),
    tagify = new Tagify (inputElm);


  window.addEventListener("trix-file-accept", function(event) {
    const acceptedTypes = [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/pdf',
      'image/jpeg',
      'text/plain',
      'image/jpg',
    ]

    if (!acceptedTypes.includes(event.file.type)) {
      event.preventDefault()
      alert("We only support common Microsoft Office documents, pdf, and images");
    }
  });

  $(".alert-message").fadeOut(5000, function() { $(this).remove(); });

  $('th').click(function () {
    var table = $(this).parents('table').eq(0)
    var rows = table.find('tr:gt(0)').toArray().sort(comparer($(this).index()))
    this.asc = !this.asc
    if (!this.asc) { rows = rows.reverse() }
    for (var i = 0; i < rows.length; i++) { table.append(rows[i]) }
  })
  function comparer(index) {
    return function (a, b) {
      var valA = getCellValue(a, index), valB = getCellValue(b, index)
      return $.isNumeric(valA) && $.isNumeric(valB) ? valA - valB : valA.toString().localeCompare(valB)
    }
  }
  
  function getCellValue(row, index) { return $(row).children('td').eq(index).text() }

  AutoNumeric.multiple('.autonumeric-js', { allowDecimalPadding: false, unformatOnSubmit: true }); 
  // $('[data-toggle="tooltip"]').tooltip({ container: 'body' })
  // $('[data-toggle="popover"]').popover()

  if(window.location.href.split('#').length > 1) {
    const tabToActivate = window.location.href.split('#')[1];
    $('a[href="#' + tabToActivate + '"]').trigger('click');
  }
});

document.addEventListener("turbolinks:before-cache", function () {
  $('[data-toggle="tooltip"]').tooltip('hide');
});

document.addEventListener("turbolinks:load", () => {
  if($('.search') === null) {return};

    $('.search').on('keyup',function() {
        var searchTerm = $(this).val().toLowerCase();
        $('.table-search-js tbody tr').each(function() {
            var lineStr = $(this).text().toLowerCase();
            if(lineStr.indexOf(searchTerm) === -1) {
                $(this).hide();
            }else{
                $(this).show();
            }
        });
    });

  if($('.search-contacts') === null) {return};
    $('.search-contacts').on('keyup',function() {
        var searchTerm = $(this).val().toLowerCase();
        $('.search-wrapper-js').each(function() {
            var lineStr = $(this).text().toLowerCase();
            if(lineStr.indexOf(searchTerm) === -1) {
                $(this).hide();
            }else{
                $(this).show();
            }
        });
    });

    $(".btn-tag").on("click", function(event) {
      event.preventDefault();
      if($(event.target).hasClass("tag-selected")) {
        $(this).removeClass("btn-primary");
        $(this).removeClass("tag-selected");
        $(this).addClass("btn-outline-secondary");
        $(".search-wrapper-js").show();
      } else {
        $(".tag-selected").removeClass("btn-primary");
        $(".tag-selected").addClass("btn-outline-secondary");
        $(this).removeClass();
        $(this).addClass("btn-tag btn btn-primary btn-block tag-selected");

        let searchTerm = $(this).text().toLocaleLowerCase();

        $('.search-wrapper-js').each(function() {
            let tags = $(this).find(".c-tags-js");

            let tagArray = tags.text().split("\n")

            let newTags = tagArray.filter(function (str) {
              return /\S/.test(str);
            })

            let finalTags = newTags.map( e => e.trim())

            let result = finalTags.filter(e => e.toLocaleLowerCase() == searchTerm );

            if(result.length > 0) {
                $(this).show();
            }else{
                $(this).hide();
            }
        });
      }
    });

    $("#edit-contact-link-js").on("click", function(event) {
        event.preventDefault();
        var currentId = $(event.target).closest("a").data("current-id");
        $("#modalEditContact" + currentId).modal("show");
        var inputElm = document.getElementById(`registration_tag_list_${currentId}`),
          tagify = new Tagify (inputElm);
        });

    $("#toggle-active-contact-js").on("click", function(event) {
        event.preventDefault();
        var currentId = $(event.target).closest("a").data("current-id");
        $("#modalDeleteContact" + currentId).modal("show");
    });

    $(".schedule-item-js").on("click", function(jsEvent) {
      var modal = $('#modalCalendarEvent');
      var $target = $(jsEvent.target);
      const fundEvent = $target.data("fund-event");

      const { id, all_day, date, end_date, subject } = fundEvent;

      modal.modal('show');

      modal.find('.event-desc').text(subject);

      modal.find('.event-start-date').text(moment(date, moment.ISO_8601).format("LLL"));

      if(end_date !== null) {
        modal.find('.event-start-date').text(moment(end_date, moment.ISO_8601).format("LLL"));
      } else {
        if(all_day) {
          modal.find('.event-end-date').text("All Day");
        } else {
          modal.find('.event-start-date').text(moment(end_date, moment.ISO_8601).format("LLL"));
        }
      }

      modal.find('.nav-link').attr('href', `/gp_dashboard/fund_events/${id}/edit`);
      modal.find('.nav-link.trash').attr('href', `/gp_dashboard/fund_events/${id}`);

    //styling
    //modal.find('.modal-header').css('backgroundColor', (calEvent.source.borderColor)? calEvent.source.borderColor : calEvent.borderColor);
  });

});


$(document).on('ready', function() {
  $('.dropdown-link').dropdown();
  
  if (gon && gon.event_hash) {

    // Calendar Event Source
    let hashEvent = gon.event_hash; 
    var calendarEvents = hashEvent


    // Initialize tooltip
    // $('[data-toggle="tooltip"]').tooltip()

    // Sidebar calendar
    $('#calendarInline').datepicker({
      showOtherMonths: true,
      selectOtherMonths: true,
      beforeShowDay: function(date) {

        // add leading zero to single digit date
        var day = date.getDate();
        console.log(day);
        return [true, (day < 10 ? 'zero' : '')];
      }
    });

    // Initialize fullCalendar

    $('#calendar').fullCalendar({
      height: 'parent',
      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'month,agendaWeek,agendaDay,listMonth'
      },
      navLinks: true,
      selectable: true,
      selectLongPressDelay: 100,
      editable: true,
      nowIndicator: true,
      defaultView: 'listMonth',
      views: {
        agenda: {
          columnHeaderHtml: function(mom) {
            return '<span>' + mom.format('ddd') + '</span>' +
                   '<span>' + mom.format('DD') + '</span>';
          }
        },
        day: { columnHeader: false },
        listMonth: {
          listDayFormat: 'ddd DD',
          listDayAltFormat: false
        },
        listWeek: {
          listDayFormat: 'ddd DD',
          listDayAltFormat: false
        },
        agendaThreeDay: {
          type: 'agenda',
          duration: { days: 3 },
          titleFormat: 'MMMM YYYY'
        }
      },
      eventSources: [calendarEvents],
      eventAfterAllRender: function(view) {
        if(view.name === 'listMonth' || view.name === 'listWeek') {
          var dates = view.el.find('.fc-list-heading-main');
          dates.each(function(){
            var text = $(this).text().split(' ');
            var now = moment().format('DD');

            $(this).html(text[0]+'<span>'+text[1]+'</span>');
            if(now === text[1]) { $(this).addClass('now'); }
          });
        }

        console.log(view.el);
      },
      eventRender: function(event, element) {

        if(event.description) {
          element.find('.fc-list-item-title').append('<span class="fc-desc">' + event.description + '</span>');
          element.find('.fc-content').append('<span class="fc-desc">' + event.description + '</span>');
        }

        var eBorderColor = (event.source.borderColor)? event.source.borderColor : event.borderColor;
        element.find('.fc-list-item-time').css({
          color: eBorderColor,
          borderColor: eBorderColor
        });

        element.find('.fc-list-item-title').css({
          borderColor: eBorderColor
        });

        element.css('borderLeftColor', eBorderColor);
      },
    });

    var calendar = $('#calendar').fullCalendar('getCalendar');

    calendar.on('eventClick', function(calEvent, jsEvent, view){
      var modal = $('#modalCalendarEvent');

      modal.modal('show');
      modal.find('.event-title').text(calEvent.title);

      if(calEvent.description) {
        modal.find('.event-desc').text(calEvent.description);
        // modal.find('.event-desc').prev().removeClass('d-none');
      } else {
        modal.find('.event-desc').text('');
        // modal.find('.event-desc').prev().addClass('d-none');
      }

      modal.find('.event-start-date').text(moment(calEvent.start).format('LLL'));
      modal.find('.event-end-date').text(moment(calEvent.end).format('LLL'));
      modal.find('.nav-link').attr('href', `/gp_dashboard/fund_events/${calEvent.id}/edit`);
      modal.find('.nav-link.trash').attr('href', `/gp_dashboard/fund_events/${calEvent.id}`);

      //styling
      modal.find('.modal-header').css('backgroundColor', (calEvent.source.borderColor)? calEvent.source.borderColor : calEvent.borderColor);
    });
    // change view to week when in tablet
    if(window.matchMedia('(min-width: 576px)').matches) {
      calendar.changeView('agendaWeek');
    }

    // change view to month when in desktop
    if(window.matchMedia('(min-width: 992px)').matches) {
      calendar.changeView('month');
    }

    // change view based in viewport width when resize is detected
    calendar.option('windowResize', function(view) {
      if(view.name === 'listWeek') {
        if(window.matchMedia('(min-width: 992px)').matches) {
          calendar.changeView('month');
        } else {
          calendar.changeView('listWeek');
        }
      }
    });


    // display current date
    var dateNow = calendar.getDate();
    calendar.option('select', function(startDate, endDate){
      $('#modalCreateEvent').modal('show');
      $('#eventStartDate').val(startDate.format('LL'));
      $('#eventEndDate').val(endDate.format('LL'));

      $('#eventStartTime').val(startDate.format('LT')).trigger('change');
      $('#eventEndTime').val(endDate.format('LT')).trigger('change');
    });

    $('.calendar-add').on('click', function(e){
      e.preventDefault()

      $('#modalCreateEvent').modal('show');
    });
  }

});
